/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import arrowLeft from '@assets/images/arrow-left.svg';
import arrowRight from '@assets/images/arrow-right.svg';
import RoutingPath from '@constants/routingPath';
import { useRouter } from 'next/router';
import useIsAndroid from '@hooks/useIsAndroid';
import { AMPLITUDE_EXPERIMENT_VARIANT_NAME } from '@constants/amplitudeExperiments';
import { useAmplitudeExperimentAB } from '@hooks/useAmplitudeExperiment';
import { environment } from '@helpers/environment';
import {
  SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED,
  SKINNY_BANNER_EMAIL_CTA_CLICKED,
} from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';
import { store } from '@context/store';
import actionTypes from '@context/actionTypes';

const marketingEmailSignupId = environment.isProduction
  ? 'marketing-email-signup'
  : 'marketing-email-signup-staging';

export default function SkinnyBanner() {
  const { dispatch } = useContext(store);
  const router = useRouter();
  const isAnAndroidDevice = useIsAndroid();
  const amplitudeExperiment = useAmplitudeExperimentAB('skinny-banner-black-friday');
  const { track } = useEvents();

  const [isFading, setIsFading] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const path = router.pathname;

  const messages = useMemo(
    () => [
      {
        message: 'Get Custom Offers',
        cta: {
          text: 'Enter Email',
          link: '#',
          id: marketingEmailSignupId,
        },
      },
      {
        message: 'Risk-Free Cancellation',
      },
      {
        message: 'Get the ResortPass App',
        cta: {
          text: 'Download',
          link: isAnAndroidDevice
            ? 'https://play.google.com/store/apps/details?id=com.resortpass.app'
            : 'https://itunes.apple.com/app/id1557539492',
          id: 'app-download',
        },
      },
    ],
    [isAnAndroidDevice],
  );

  const { message, cta } = messages[currentMessageIndex];

  const nextMessage = useCallback(() => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      setIsFading(false);
    }, 500);
  }, [messages.length]);

  const prevMessage = () => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex - 1 + messages.length) % messages.length);
      setIsFading(false);
    }, 500);
  };

  const handleCTAClick = () => {
    if (cta && cta.id === marketingEmailSignupId) {
      track(SKINNY_BANNER_EMAIL_CTA_CLICKED, {});
      return;
    }

    if (cta && cta.id === 'app-download') {
      track(SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED, {});
      window.open(cta.link, '_blank');
      setIsHovered(true);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (!isHovered) {
      intervalId = setInterval(nextMessage, 7000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isHovered, nextMessage]);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SKINNY_BANNER_EXPERIMENT,
      payload: amplitudeExperiment,
    });
  }, [amplitudeExperiment, dispatch]);

  return path !== RoutingPath.GUEST_CHECKOUT &&
    path !== RoutingPath.GUEST_BILLING_DETAILS &&
    amplitudeExperiment === AMPLITUDE_EXPERIMENT_VARIANT_NAME ? (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="border-b border-rp-gray-divider flex h-10 items-center justify-between w-full px-2 d:px-18px"
    >
      {/* Left Arrow Button */}
      <button type="button" onClick={prevMessage} aria-label="Previous Message">
        <Image src={arrowLeft} alt="Previous Message" width={16} height={16} />
      </button>

      {/* Message Display */}
      <div
        className={`font-rp-pn-semi-bold text-sm text-rp-navy transition-opacity duration-500 ${
          isFading ? 'opacity-0' : 'opacity-100'
        }`}
      >
        {message}{' '}
        {cta && (
          <button
            type="button"
            id={cta.id}
            onClick={handleCTAClick}
            className="cursor-pointer underline"
          >
            {cta.text}
          </button>
        )}
      </div>

      {/* Right Arrow Button */}
      <button type="button" onClick={nextMessage} aria-label="Next Message">
        <Image src={arrowRight} alt="Previous Message" width={16} height={16} />
      </button>
    </div>
  ) : null;
}
