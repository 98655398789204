import React, { useContext } from 'react';
import Image from 'next/image';
import cartWhiteMobile from '@assets/images/cart-white-mobile.svg';
import cartBlackMobile from '@assets/images/cart-black-mobile.svg';
import cartWhite from '@assets/images/cart-white.svg';
import cartBlack from '@assets/images/cart-black.svg';
import useOutsideClick from '@hooks/useOutsideClick';
import { store } from '@context/store';
import { cartIconClick } from '@events/globals';
import Link from 'next/link';
import { useCookies } from 'react-cookie';
import User from '@customTypes/user';
import { useRouter } from 'next/router';
import ButtonLoginTransparent from '../ButtonLoginTransparent';

export default function LoginAndCartMenuItem({
  isLoginMenuOpen,
  setIsLoginMenuOpen,
  isMobile,
  setIsSignUpClicked,
  onAuthButtonClick,
  isFixedBar,
  setShowDesktopMenu,
}: any) {
  const globalState = useContext(store);
  const { state } = globalState;
  const [cookies] = useCookies();
  const user: User = cookies.userInformation;
  const router = useRouter();

  const onLoginButtonClick = () => {
    setIsLoginMenuOpen(!isLoginMenuOpen);
  };

  const [outsideClickRef] = useOutsideClick((e: any) => {
    if (e.target && !e.target.classList.contains('login-header')) {
      setIsLoginMenuOpen(false);
    }
  });

  const navigateToCheckoutConfirmation = () => {
    cartIconClick();

    if (state.numberOfCartItems > 0) {
      window.location.href = '/products/checkout-confirmation';
    }
  };

  return (
    <>
      <div
        className={`absolute pt-0.5 right-4 flex items-center z-200 justify-center ${
          isFixedBar ? 'top-0 d:top-2 d:right-10' : 'top-10 d:top-20 d:pt-0 d:right-10'
        }`}
      >
        {isFixedBar ? (
          <>
            <div className="mr-3 h-12 mt-2.5 d:mt-0 z-300">
              <ButtonLoginTransparent
                onClick={onLoginButtonClick}
                isFixedHeader
                classes="login-header"
                isLoginMenuOpen={isLoginMenuOpen}
              />
            </div>
            {isMobile ? (
              <>
                <div className="relative h-10 w-auto">
                  <Image
                    src={cartBlackMobile}
                    alt="cart"
                    className="cursor-pointer"
                    onClick={navigateToCheckoutConfirmation}
                  />
                </div>
                {state.numberOfCartItems > 0 && (
                  <div
                    className="bg-rp-primary border border-white text-white font-rp-pn-regular h-5 w-5 rounded-full text-center flex justify-center items-center text-11 pt-px z-100 absolute -right-1 top-2"
                    id="header-cart-item-count"
                  >
                    {state.numberOfCartItems}
                  </div>
                )}
              </>
            ) : (
              <>
                <Image
                  src={cartBlack}
                  alt="cart"
                  height={56}
                  width={56}
                  className="cursor-pointer hover:opacity-70"
                  onClick={navigateToCheckoutConfirmation}
                />
                {state.numberOfCartItems > 0 && (
                  <div
                    className="bg-rp-primary border border-white text-white font-rp-pn-regular h-6 w-6 rounded-full text-center flex justify-center items-center text-13 pt-px z-100 absolute right-0 top-0"
                    id="header-cart-item-count"
                  >
                    {state.numberOfCartItems}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className="mr-3 h-12 mt-2 d:mt-0 z-200">
              <ButtonLoginTransparent
                onClick={onLoginButtonClick}
                classes={`login-header ${
                  isLoginMenuOpen ? 'bg-white text-black' : 'bg-transparent text-white'
                }`}
                isLoginMenuOpen={isLoginMenuOpen}
              />
            </div>
            {isMobile ? (
              <>
                <div className="relative h-10 w-auto">
                  <Image
                    src={cartWhiteMobile}
                    alt="cart"
                    className="cursor-pointer"
                    onClick={navigateToCheckoutConfirmation}
                  />
                </div>
                {state.numberOfCartItems > 0 && (
                  <div
                    className="bg-rp-primary border border-white text-white font-rp-pn-regular h-5 w-5 rounded-full text-center flex justify-center items-center text-11 pt-px z-100 absolute -right-1 top-2"
                    id="header-cart-item-count"
                  >
                    {state.numberOfCartItems}
                  </div>
                )}
              </>
            ) : (
              <>
                <Image
                  src={cartWhite}
                  alt="cart"
                  height={56}
                  width={56}
                  className="cursor-pointer"
                  onClick={navigateToCheckoutConfirmation}
                />
                {state.numberOfCartItems > 0 && (
                  <div
                    className="bg-rp-primary border border-white text-white font-rp-pn-regular h-6 w-6 rounded-full text-center flex justify-center items-center text-13 pt-px z-100 absolute right-0 top-0"
                    id="header-cart-item-count"
                  >
                    {state.numberOfCartItems}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {isLoginMenuOpen && (
        <div
          className={`absolute bg-white text-rp-primary-black -mt-1 rounded-xl w-72 z-300 flex flex-col items-start shadow-login-card right-16 d:right-26 ${
            isFixedBar ? 'top-18 d:top-22' : 'top-28 d:top-38'
          }`}
          ref={outsideClickRef}
        >
          <button
            type="button"
            className="pl-5 pb-4 pt-4 cursor-pointer z-100 w-full text-left align-middle rounded-t-xl hover:bg-rp-light-gray-4"
            onClick={() => {
              if (user) {
                router.push('/users/bookings');
                return;
              }
              setIsSignUpClicked(false);
              setShowDesktopMenu(false);
              onAuthButtonClick(!!user);
            }}
          >
            {user ? 'My Account' : 'Login'}
          </button>
          <button
            type="button"
            className="pl-5 pb-3 cursor-pointer z-100 w-full text-left align-middle pt-2 border-b border-rp-gray-divider hover:bg-rp-light-gray-4"
            onClick={() => {
              setIsSignUpClicked(true);
              onAuthButtonClick(!!user);
              setShowDesktopMenu(false);
              setIsLoginMenuOpen(false);
            }}
          >
            {user ? 'Log Out' : 'Sign Up'}
          </button>
          <Link href={`${process.env.NEXT_PUBLIC_RAILS_APP_URL}/hotel_users/sign_in`}>
            <button
              type="button"
              className="pl-5 pb-4 cursor-pointer z-100 w-full text-left align-middle pt-3 hover:bg-rp-light-gray-4"
              onClick={() => {
                setShowDesktopMenu(false);
              }}
            >
              Hotel Login
            </button>
          </Link>
          <Link href="https://partners.resortpass.com/">
            <button
              type="button"
              className="pl-5 pb-4 cursor-pointer z-100 w-full text-left align-middle pt-2 rounded-b-xl hover:bg-rp-light-gray-4"
              onClick={() => {
                setShowDesktopMenu(false);
              }}
            >
              List My Hotel
            </button>
          </Link>
        </div>
      )}
    </>
  );
}
