import { store } from '@context/store';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import HeaderLinksV2 from '@components/HeaderLinks/HeaderLinksV2';
import Link from 'next/link';

type Props = {
  showDesktopMenu: boolean;
  setShowDesktopMenu: Dispatch<SetStateAction<boolean>>;
  openCurrencySelectorModal: () => void;
};
const linkStyle =
  'text-xl py-3 text-rp-primary-black font-rp-pn-semi-bold cursor-pointer hover:underline';

export default function DesktopMenu({
  showDesktopMenu,
  setShowDesktopMenu,
  openCurrencySelectorModal,
}: Props) {
  const globalState = useContext(store);
  const { state } = globalState;
  const { selectedCurrency } = state;

  const leftPanelStyle = `${
    showDesktopMenu ? '' : '-translate-x-full'
  } fixed shadow-desktop-menu inset-y-0 left-0 w-full z-300 bg-white transform transition duration-1000 ease-in-out text-rp-primary-black h-433 top-29.6`;

  const backgroundStyle = `${
    showDesktopMenu ? 'opacity-70' : 'opacity-0 pointer-events-none'
  } fixed inset-y-0 left-0 w-full h-screen bg-black transform transition-all duration-300 ease-in-out z-250`;

  return (
    <div className="flex h-433 absolute">
      <nav className={leftPanelStyle}>
        <div className="flex pl-36 pt-8">
          <div className="font-rp-gelica-light text-4xl w-1/3 pt-7 mr-20">
            <p className="pb-3 text-rp-primary-new -tracking-0.3">
              Unforgettable resorts and memories await
            </p>
            <Link href="https://partners.resortpass.com/about-us">
              <a
                className={`${linkStyle}`}
                role="link"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setShowDesktopMenu(false);
                  }
                }}
                onClick={() => {
                  setShowDesktopMenu(false);
                }}
                tabIndex={0}
              >
                About ResortPass
              </a>
            </Link>
          </div>

          <div className="flex flex-col px-4 overflow-hidden pt-8">
            <div className="flex mb-3 text-rp-disabled font-rp-pn-regular text-base">
              <p className="leading-20px mr-56">Guests</p>
              <p className="pl-7 leading-20px ml-0.5">Hotels</p>
            </div>

            <button
              type="button"
              className={`text-left pt-5 ${linkStyle}`}
              onClick={openCurrencySelectorModal}
            >
              Currency
              <span className="ml-3 bg-rp-light-background text-rp-primary-black text-15 px-3 py-1 rounded-md">{`${selectedCurrency.symbol} ${selectedCurrency.iso_code}`}</span>
            </button>

            {/* Navigation links */}
            <HeaderLinksV2
              linkStyle={linkStyle}
              setShowMenu={setShowDesktopMenu}
              isGuestSelected
              isDesktop
            />
          </div>
        </div>
      </nav>

      <div
        className={backgroundStyle}
        onClick={() => setShowDesktopMenu(false)}
        onKeyDown={() => setShowDesktopMenu(false)}
        role="button"
        tabIndex={0}
        aria-label="close menu"
      />
    </div>
  );
}
