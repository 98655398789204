/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { useCookies } from 'react-cookie';
import useAuthentication from '@hooks/useAuthentication';
import { store } from '@context/store';
import ModalContainer from '@components/AuthForm/ModalContainer';
import { srpShowOnlyAvailableFilterApplied, srpDateChange } from '@events/SRP';
import Modal from '@components/common/Modal/Modal';
import CurrencyList from '@components/CurrencyList/CurrencyList';
import { accessAllFavorites, getCurrencyData, getLocations } from '@utils/services';
import { CurrencyData } from '@customTypes/currency';
import actionTypes from '@context/actionTypes';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import toggleErrorPopup from '@helpers/toggleErrorPopup';
import { UserWithFavorites } from '@customTypes/user';
import headerHasH1 from '@constants/headerHasH1';
import {
  buildUserInfoObject,
  clearExistingCookiesBeforeAddingNewOne,
  decodeJWT,
  doesImpersonationCookieExist,
} from '@helpers/userImpersonationFunctions';
import updateLocalUserInformation from '@helpers/updateLocalUserInformation';
import useIsMobile from '@hooks/useIsMobile';
import rpLogo from '@assets/images/rp-logo-mark.svg';
import { myFavoritesCreateAccount } from '@events/myFavorites';
import CookiesBanner from '@components/common/CookiesBanner/CookiesBanner';
import { addLocalStorageHotelsToFavoritesUponLogin } from '@helpers/addHotelToFavorites';
import { getCookieValue } from '@helpers/cookies';
import { filterIconClicked } from '@events/filters';
import whiteLogo from '@assets/images/resortpass-white-logo.svg';
import hamburgerNew from '@assets/images/desktop-hamburger.svg';
import searchHeaderIcon from '@assets/images/search-header-icon.svg';
import MobileMenuV2 from '@components/MobileMenu/MobileMenuV2';
import LoginAndCartMenuItem from '@components/common/LoginAndCartMenuItem/LoginAndCartMenuItem';
import useIsStickyHeaderVisible from '@hooks/useIsStickyHeaderVisible';
import DesktopMenu from '@components/DesktopMenu/DesktopMenu';
import closeButtonBlack from '@assets/images/close-black-light.svg';
import filter from '@assets/images/filter.svg';
import hamburger from '@assets/images/hamburger-menu.svg';
import ReactSelectSearch from '@components/common/ReactSelectSearch/ReactSelectSearch';
import calendarHeaderIcon from '@assets/images/calendar-header-icon.svg';
import ReactDatePicker from '@components/common/ReactDatePicker/ReactDatePicker';
import PastDateError from '@components/common/ReactDatePicker/PastDateError';
import useOutsideClick from '@hooks/useOutsideClick';
import HomePageVideo from '@components/HomePageVideo/HomePageVideo';
import useBannerMargin from '@hooks/useBannerMargin';
import useSmartCalendar from '@hooks/useSmartCalendar';
import RoutingPath from '@constants/routingPath';
import HeaderSearchBar from '@components/SearchBar/HeaderSearchBar';
import SearchBarMobile from '@components/SearchBar/Mobile/SearchBarMobile';
import { useSearchContext } from '@context/SearchContext';
import { ExperimentType } from '@customTypes/context';
import SkinnyBanner from './SkinnyBanner';

type Props = {
  searchBarEnabled?: boolean;
  hideVideo?: boolean;
  travelerSeason?: ExperimentType;
  searchProps?: any;
};

const defaultProps = {
  searchBarEnabled: false,
  hideVideo: false,
  searchProps: {},
  travelerSeason: null,
};

export default function Header({
  searchBarEnabled,
  hideVideo,
  searchProps,
  travelerSeason,
}: Props) {
  const session = useSession();
  const router = useRouter();
  const { getItem } = useSessionStorage();
  const iterableEmail = getItem(sessionStorageKeys.ITERABLE_EMAIL);
  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const [isLoginButtonClicked, setIsLoginButtonClicked] = useState<boolean>(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState<boolean>(false);
  const [verticalScroll, setVerticalScroll] = useState<number>(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);
  const [isLoginMenuOpen, setIsLoginMenuOpen] = useState<boolean>(false);
  const [isSignUpClicked, setIsSignUpClicked] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Log in / Sign up');
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [showDesktopMenu, setShowDesktopMenu] = useState<boolean>(false);
  const [isSrpOnlyAvailableFilterReportedToGTM, setIsSrpOnlyAvailableFilterReportedToGTM] =
    useState<boolean>(false);
  const [showSearchBarBottomDrawer, setShowSearchBarBottomDrawer] = useState<boolean>(false);
  const [isCurrencySelectorModalOpen, setIsCurrencySelectorModalOpen] = useState<boolean>(false);
  const [currencyData, setCurrencyData] = useState<CurrencyData>();
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { onlyShowAvailableHotels, searchDate, totalFilterCount } = state;
  const { setSearchResults } = useSearchContext();
  const { oauthLogin, logoutUser } = useAuthentication();
  const isStickyVisible = useIsStickyHeaderVisible();
  const isMobile = useIsMobile();
  const headerMarginTop = useBannerMargin();
  const { value, setValue, locations, setLocations, input, searchWithoutQuery } = searchProps;
  const [outsideClickRef] = useOutsideClick(() => {
    if (showErrorBanner) {
      setShowErrorBanner(false);
    }
  });
  const { removeDate, setDate } = useSmartCalendar();

  const isHomePage = useMemo(() => router.pathname === RoutingPath.HOME_PAGE, [router.pathname]);
  const { showCookiesConsentBanner, userLocation } = state;

  const updateSearchDate = (dateValue: Date | undefined) => {
    if (dateValue) {
      setDate(dateValue);

      if (router.pathname === '/hotel-day-passes' || router.pathname === '/[results]') {
        // Event
        srpDateChange(dateValue);
      }
    } else {
      removeDate();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['userInformation']);

  const onHamburgerClick = () => {
    if (isMobile) setShowMobileMenu(true);
    if (!isMobile) setShowDesktopMenu(true);
  };

  const onAuthButtonClick = async (isLogout: boolean = false) => {
    if (isLogout) {
      await logoutUser();
      setButtonText('Log in / Sign up');

      if (
        router.pathname.indexOf('/hotels/') > -1 ||
        router.pathname.indexOf('/checkout-confirmation')
      ) {
        router.reload();
      }

      if (window.location.href.includes('my-favorites')) {
        window.location.reload();
      }
    } else {
      setIsLoginButtonClicked(!isLoginButtonClicked);
      setIsLoginMenuOpen(false);
    }
  };

  useEffect(() => {
    const isUserAuthenticated =
      session.status && session.status !== 'unauthenticated' && session.status !== 'loading';
    if (isUserAuthenticated && !isUserSignedIn && !cookies.userInformation?.email) {
      (async () => {
        try {
          const cartId = getItem(sessionStorageKeys.CART_ID);
          const userInfo = await oauthLogin(session, cartId);

          if (userInfo === 'registered_with_email') {
            setButtonText('Log in / Sign up');
            toggleErrorPopup(
              'This email is already registered. Please use the regular login.',
              dispatch,
            );
            return;
          }

          if (!!userInfo && userInfo !== 'error') {
            const userWithFavorites: UserWithFavorites = {
              ...userInfo,
              favorites: Object.keys(await accessAllFavorites(userInfo)) || [],
            };

            setCookie('userInformation', userWithFavorites, { path: '/' });
            setButtonText('Log out');
            setIsUserSignedIn(true);

            if (window.Iterable) {
              await window.Iterable.updateUserEmail(
                userInfo.email,
                iterableEmail || `${sessionID}@placeholder.email`,
                userInfo,
              );
            }

            if (window.location.href.includes('my-favorites')) {
              myFavoritesCreateAccount();
              window.location.reload();
            }

            await addLocalStorageHotelsToFavoritesUponLogin(userWithFavorites, getItem);
          }
        } catch (error) {
          setButtonText('Log in / Sign up');
          setIsUserSignedIn(false);
        }
      })();
    }
    if (cookies.userInformation?.email && cookies.userInformation?.id) {
      setButtonText('Log out');
    }
    if (cookies.userInformation === null) {
      setButtonText('Log in / Sign up');
    }
    // Change in Session Redirect Behavior
    // https://developers.facebook.com/blog/post/552/
    if (window.location.hash === '#_=_') {
      window.location.hash = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    session,
    setCookie,
    cookies,
    buttonText,
    isUserSignedIn,
    removeCookie,
    dispatch,
    isLoginButtonClicked,
    router,
  ]);

  const setUserLocation = useCallback(
    async (userCityAndState: string) => {
      if (userCityAndState && !userLocation) {
        const response = await getLocations(`"${userCityAndState}"`);

        dispatch({
          type: actionTypes.SET_USER_LOCATION,
          payload: response && response.length > 0 ? response[0] : undefined,
        });

        setSearchResults(response);
      }
    },
    [dispatch, userLocation, setSearchResults],
  );

  // Set currency data
  useEffect(() => {
    (async () => {
      try {
        const currencyList = await getCurrencyData();
        setCurrencyData(currencyList);
        const previouslySelectedCurrency = getItem(sessionStorageKeys.SELECTED_CURRENCY);
        const defaultCurrency = currencyList.default_currency;
        const defaultCurrencyWithExchangeRate = currencyList.currencies.find(
          (item) => item.name === defaultCurrency.name,
        );
        dispatch({
          type: actionTypes.UPDATE_SELECTED_CURRENCY,
          payload: previouslySelectedCurrency
            ? JSON.parse(previouslySelectedCurrency)
            : defaultCurrencyWithExchangeRate,
        });
        dispatch({
          type: actionTypes.UPDATE_DEFAULT_CURRENCY,
          payload: defaultCurrencyWithExchangeRate,
        });

        const userCityAndState =
          currencyList.city && currencyList.state
            ? `${currencyList.city}, ${currencyList.state}`
            : '';
        if (userCityAndState) {
          dispatch({
            type: actionTypes.USER_CITY_AND_STATE,
            payload: userCityAndState ?? '',
          });
          setUserLocation(userCityAndState);
        }
      } catch (error) {
        // No error popup. Fail silently.
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCurrencySelectorModal = () => {
    setIsCurrencySelectorModalOpen(true);
    setShowMobileMenu(false);
    setShowDesktopMenu(false);
  };

  const closeCurrencySelectorModal = () => {
    setIsCurrencySelectorModalOpen(false);
  };

  useEffect(() => {
    const storedDate = getItem(sessionStorageKeys.SMART_CALENDAR_DATE);
    if (storedDate) {
      updateSearchDate(new Date(storedDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const decodedJWT: any = cookies.userInformation?.email
      ? decodeJWT(cookies.userInformation?.token)
      : null;
    if (decodedJWT && decodedJWT.exp < Date.now() / 1000) {
      removeCookie('userInformation', { path: '/' });
      setButtonText('Log in / Sign up');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onlyShowAvailableHotels && !isSrpOnlyAvailableFilterReportedToGTM) {
      // Event
      srpShowOnlyAvailableFilterApplied();

      setIsSrpOnlyAvailableFilterReportedToGTM(true);
    }
  }, [isSrpOnlyAvailableFilterReportedToGTM, onlyShowAvailableHotels]);

  useEffect(() => {
    const ENV = process.env.NEXT_PUBLIC_NEXT_ENV || 'production';
    if (doesImpersonationCookieExist(`${ENV}_impersonation_jwt`)) {
      const impersonationJWT = getCookieValue(`${ENV}_impersonation_jwt`);
      const decodedJWT = decodeJWT(impersonationJWT);
      const userImpersonationInfo = buildUserInfoObject(decodedJWT, impersonationJWT);
      if (cookies.userInformation?.email !== userImpersonationInfo.email) {
        clearExistingCookiesBeforeAddingNewOne(removeCookie);
        updateLocalUserInformation(userImpersonationInfo, userImpersonationInfo.token, setCookie);
        if (window.location.href.includes('sign-in')) {
          router.push('/users/bookings');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;
      if (verticalScroll > window?.scrollY) {
        setIsHeaderVisible(true);
      } else if (verticalScroll < window.scrollY && window?.scrollY > 10) {
        setIsHeaderVisible(false);
      }
      if (window?.scrollY === 0) {
        setIsHeaderVisible(true);
      }
      setVerticalScroll(window?.scrollY);
    },
    [verticalScroll],
  );

  const openFilters = () => {
    dispatch({
      type: actionTypes.OPEN_FILTERS,
      payload: true,
    });
    let storedLocationObj = null;
    const selectedLocationString = getItem(sessionStorageKeys.SEARCHED_LOCATION);
    if (selectedLocationString) {
      storedLocationObj = JSON.parse(selectedLocationString);
    }
    // GA4 event
    filterIconClicked(storedLocationObj, searchDate);
  };

  useEffect(() => {
    if (isLoginMenuOpen) {
      setShowDesktopMenu(false);
    }
  }, [isLoginMenuOpen]);

  useEffect(() => {
    if (searchBarEnabled && isMobile) {
      setVerticalScroll(window?.scrollY);
      window.addEventListener('scroll', handleNavigation);
    }

    return () => {
      if (searchBarEnabled && isMobile) {
        window?.removeEventListener('scroll', handleNavigation);
      }
    };
  }, [handleNavigation, isMobile, searchBarEnabled]);

  const headerMobileClasses = `${
    isHeaderVisible ? 'h-auto' : 'opacity-0 h-0 max-h-0'
  } py-4 flex items-center px-5 transform transition duration-300 ease-linear justify-start`;

  const searchBarMobileClasses = `${
    isHeaderVisible ? 'h-auto pb-4 pt-4' : '-translate-y-4 pb-4'
  } px-5 flex flex-col items-center justify-center transform transition duration-300 ease-linear border-b border-solid`;

  return (
    <>
      <HomePageVideo
        showBottomDrawer={showSearchBarBottomDrawer}
        setShowBottomDrawer={setShowSearchBarBottomDrawer}
        isStickyHeaderVisible={isStickyVisible}
        hideVideo={hideVideo}
        travelerSeason={travelerSeason}
      />

      {!isStickyVisible && !showDesktopMenu ? (
        <div>
          <div className="absolute top-12 flex items-center justify-items-start z-100 d:left-10 d:top-20">
            <div className="relative w-14 h-auto z-100 cursor-pointer d:w-auto">
              <Image src={hamburgerNew} alt="hamburger" onClick={onHamburgerClick} />
            </div>
            <div className="relative w-40 h-auto cursor-pointer -ml-2 d:ml-0 d:w-auto">
              <Link href="/">
                <a>
                  <Image src={whiteLogo} alt="resortpass logo" />
                </a>
              </Link>
            </div>
          </div>

          <LoginAndCartMenuItem
            isLoginMenuOpen={isLoginMenuOpen}
            setIsLoginMenuOpen={setIsLoginMenuOpen}
            isMobile={isMobile}
            setIsSignUpClicked={setIsSignUpClicked}
            onAuthButtonClick={onAuthButtonClick}
            isFixedBar={false}
            setShowDesktopMenu={setShowDesktopMenu}
          />

          {showMobileMenu && (
            <MobileMenuV2
              showMobileMenu={showMobileMenu}
              setShowMobileMenu={setShowMobileMenu}
              openCurrencySelectorModal={openCurrencySelectorModal}
            />
          )}

          {showDesktopMenu && (
            <DesktopMenu
              showDesktopMenu={showDesktopMenu}
              setShowDesktopMenu={setShowDesktopMenu}
              openCurrencySelectorModal={openCurrencySelectorModal}
            />
          )}
        </div>
      ) : (
        <>
          <header
            className={`left-0 w-full bg-white shadow-mobile-header d:shadow-header ${
              hideVideo ? 'sticky z-100' : 'fixed d:z-400 z-450'
            }`}
            style={{ top: headerMarginTop }}
          >
            <SkinnyBanner />

            <div
              className={`${headerMobileClasses} d:flex d:h-78 d:pl-6 d:pr-5 d:py-4 z-450 relative ${
                searchBarEnabled ? 'd:border-b d:border-solid d:border-rp-gray-divider' : ''
              }`}
            >
              {/* Hamburger logo */}
              {showDesktopMenu ? (
                <div className="relative h-12 w-12 rounded-full mt-1 ml-4 hover:bg-rp-light-gray-4 flex items-center justify-center cursor-pointer">
                  <div className="absolute h-6 w-6 cursor-pointer">
                    <Image
                      src={closeButtonBlack}
                      alt="close"
                      onClick={() => setShowDesktopMenu(false)}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {isMobile ? (
                    <div className="h-6 w-6 relative mr-2 mt-1 d:ml-8 cursor-pointer">
                      <Image src={hamburger} alt="menu" layout="fill" onClick={onHamburgerClick} />
                    </div>
                  ) : (
                    <div className="relative h-12 w-12 rounded-full mt-1 hover:bg-rp-light-gray-4 flex items-center justify-center cursor-pointer d:ml-4">
                      <div className="h-6 w-6 absolute cursor-pointer">
                        <Image
                          src={hamburger}
                          alt="menu"
                          layout="fill"
                          onClick={onHamburgerClick}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* ResortPass logo */}
              {headerHasH1(router.route) ? (
                <div>
                  <h1 className="h-6 w-40 mt-1 items-center relative d:h-12 d:w-64 d:cursor-pointer d:py-3 d:ml-1">
                    <Link href="/">
                      <a>
                        <Image src={rpLogo} alt="ResortPass" layout="fill" />
                      </a>
                    </Link>
                  </h1>
                </div>
              ) : (
                <div className="h-6 w-40 mt-1 items-center relative d:h-12 d:w-64 d:cursor-pointer d:py-3 d:ml-1">
                  <Link href="/">
                    <a>
                      <Image src={rpLogo} alt="ResortPass" layout="fill" />
                    </a>
                  </Link>
                </div>
              )}

              {isHomePage && !isMobile ? <HeaderSearchBar /> : null}

              <LoginAndCartMenuItem
                isLoginMenuOpen={isLoginMenuOpen}
                setIsLoginMenuOpen={setIsLoginMenuOpen}
                isMobile={isMobile}
                setIsSignUpClicked={setIsSignUpClicked}
                onAuthButtonClick={onAuthButtonClick}
                setShowDesktopMenu={setShowDesktopMenu}
                isFixedBar
              />
            </div>

            {isHomePage && isMobile && !showMobileMenu ? (
              <div className="m-4">
                <SearchBarMobile
                  showBottomDrawer={showSearchBarBottomDrawer}
                  setShowBottomDrawer={setShowSearchBarBottomDrawer}
                />
              </div>
            ) : null}

            {searchBarEnabled && (
              <div
                className={`${searchBarMobileClasses} relative pointer-events-none d:z-auto d:h-78 d:flex-row d:pl-6 d:pr-5 d:py-4 d:border-b-0`}
              >
                <div className="box-content h-46 flex flex-row items-center relative shadow-search-header w-full border border-solid border-rp-gray-border rounded-xl pointer-events-auto d:w-454 d:h-50">
                  <div className="ml-3 mt-0.5 w-8%">
                    <Image src={searchHeaderIcon} height="16" width="16" alt="search-icon" />
                  </div>
                  <div className="w-45% ml-0 overflow-ellipsis d:w-3/5">
                    <ReactSelectSearch
                      value={value}
                      setValue={setValue}
                      locations={locations}
                      setLocations={setLocations}
                      input={input}
                      headerVariant
                      searchWithoutQuery={searchWithoutQuery}
                    />
                  </div>
                  <div className="w-32% h-6 mb-1 flex flex-row items-center pl-3 box-content mr-3 border-l border-r border-solid border-rp-gray-divider srp-date-picker -ml-3 d:ml-0 d:border-r-0">
                    <div className="box-content w-5 h-5 relative mt-px">
                      <Image src={calendarHeaderIcon} layout="fill" alt="Calendar icon" />
                    </div>
                    <ReactDatePicker
                      classes="pl-2 mb-3px text-15 d:text-base leading-4 font-rp-pn-regular text-custom-black"
                      startDate={searchDate}
                      setStartDate={updateSearchDate}
                      showClearDate
                    />
                  </div>
                  <div
                    className={`mr-2 w-8 border-rp-primary-dark rounded-lg pl-1.5 pt-3px -ml-1 h-7 d:hidden ${
                      totalFilterCount > 0 ? 'border' : 'border-0'
                    }`}
                  >
                    {totalFilterCount > 0 && (
                      <div className="bg-rp-primary-dark text-white font-rp-pn-regular h-4 w-4 rounded-full text-center flex justify-center items-center text-11 absolute ml-3 -mt-2.5 z-100">
                        {totalFilterCount}
                      </div>
                    )}
                    <Image
                      src={filter}
                      height="16"
                      width="16"
                      alt="filter-icon"
                      onClick={openFilters}
                    />
                  </div>

                  {showErrorBanner ? (
                    <PastDateError ref={outsideClickRef} setShow={setShowErrorBanner} isSRP />
                  ) : null}
                </div>
              </div>
            )}
          </header>
          {showMobileMenu && (
            <MobileMenuV2
              showMobileMenu={showMobileMenu}
              setShowMobileMenu={setShowMobileMenu}
              openCurrencySelectorModal={openCurrencySelectorModal}
            />
          )}
          {showDesktopMenu && (
            <DesktopMenu
              showDesktopMenu={showDesktopMenu}
              setShowDesktopMenu={setShowDesktopMenu}
              openCurrencySelectorModal={openCurrencySelectorModal}
            />
          )}
        </>
      )}

      {showCookiesConsentBanner && <CookiesBanner />}

      {isLoginButtonClicked && (
        <>
          <div className="fixed w-full h-full z-500 bg-black opacity-50 top-0" />
          <div className="-translate-x-1/2 -translate-y-1/2 fixed flex flex-col items-center justify-between left-1/2 rounded-xl top-1/2 transform w-full z-500 h-full overflow-y-auto d:h-auto">
            <ModalContainer
              setIsLoginButtonClicked={setIsLoginButtonClicked}
              isSignUpButtonClicked={isSignUpClicked}
            />
          </div>
        </>
      )}

      <div className="h-full d:h-auto">
        <Modal
          isOpen={isCurrencySelectorModalOpen}
          closePopup={closeCurrencySelectorModal}
          childrenClass="pb-8 pt-0"
          containerzIndex="z-500"
          showCloseButton
          desktopWidth="d:w-auto d:max-w-856"
          overlayClasses="z-500"
        >
          <CurrencyList
            currencyData={currencyData}
            setIsCurrencySelectorModalOpen={setIsCurrencySelectorModalOpen}
          />
        </Modal>
      </div>
    </>
  );
}

Header.defaultProps = defaultProps;
